import { Vault } from './types';

const vaults: Vault[] = [
  {
    id: 'ethereum-dvsteth',
    name: 'Decentralized Validator Vault',
    description:
      'The Decentralized Validator Vault accepts ETH deposits to the Simple DVT Module, bridging the gap between robust institutional staking and decentralized native staking leveraging Distributed Validator Technology from Obol and SSV Network.',
    address: '0x5E362eb2c0706Bd1d134689eC75176018385430B',
    curator: ['Steakhouse'],
    operator: ['Steakhouse'],
    points: ['mellow', 'obol', 'ssv'],
    layer: 'Lido Simple DVT',
    network: 'ethereum',
    baseToken: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    lpTokenSymbol: 'DVstETH',
    ethDepositWithSwap: true,
    isNew: true,
  },
  {
    id: 'ethereum-re7rwbtc',
    name: 'Re7 Labs Restaked wBTC',
    description:
      'Re7 has been providing liquidity in DeFi since 2019 deploying capital over across yield strategies and liquid venture investing. As DeFi-native managers, we have focused on providing early liquidity to various DeFi protocols to help grow the ecosystem.<br /><br />Re7 Labs is the innovation arm of digital asset investment firm Re7 Capital, taking the practical experience of DeFi risk management to our on-chain LRT vault.',
    address: '0x7F43fDe12A40dE708d908Fb3b9BFB8540d9Ce444',
    curator: ['Re7 Labs'],
    operator: ['Re7 Labs'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    baseToken: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    lpTokenSymbol: 'Re7rwBTC',
    customApr: '—',
  },
  {
    id: 'ethereum-re7rtbtc',
    name: 'Re7 Labs Restaked tBTC',
    description:
      'Re7 has been providing liquidity in DeFi since 2019 deploying capital over across yield strategies and liquid venture investing. As DeFi-native managers, we have focused on providing early liquidity to various DeFi protocols to help grow the ecosystem.<br /><br />Re7 Labs is the innovation arm of digital asset investment firm Re7 Capital, taking the practical experience of DeFi risk management to our on-chain LRT vault.',
    address: '0x3a828C183b3F382d030136C824844Ea30145b4c7',
    curator: ['Re7 Labs'],
    operator: ['Re7 Labs'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    baseToken: '0x18084fbA666a33d37592fA2633fD49a74DD93a88',
    lpTokenSymbol: 'Re7rtBTC',
    customApr: '—',
  },
  {
    id: 'ethereum-amphrbtc',
    name: 'Amphor Restaked BTC',
    description:
      'Amphor Restaked BTC [amphrBTC] secures, facilitates & monitors Restaked Networks curation on Symbiotic ecosystem. <br />amprBTC LRT aims at minimizing: <br />- operator authority bias<br />- unintended slashing<br />- trust/security assumptions<br />while keeping a competitive risk-adjusted yield for wBTC restakers.',
    address: '0x64047dD3288276d70A4F8B5Df54668c8403f877F',
    curator: ['MEVcap', 'Nexo'],
    operator: ['MEVcap', 'Nexo'],
    points: ['mellow', 'symbiotic', 'amphor'],
    layer: 'Symbiotic',
    network: 'ethereum',
    baseToken: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    lpTokenSymbol: 'amphrBTC',
    customApr: '—',
  },
  {
    id: 'ethereum-roeth',
    name: 'Rockmelon ETH',
    description:
      'Restaking Vault curated by <a href="https://www.rockx.com" target="_blank">RockX</a> aims to maximize restaking opportunities for its users while managing slashing risk. <a href="https://www.rockx.com" target="_blank">RockX</a> is the largest Asian-based institutional grade staking and RPC provider globally. RockX runs validators for over 2 bil USD assets across 30 blockchains and nodes for more than 10k clients, including Lido, Matrixport, Amber, Bitkan and etc.',
    address: '0x7b31F008c48EFb65da78eA0f255EE424af855249',
    curator: ['RockX'],
    operator: ['RockX'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0x83F6c979ce7a52C7027F08119222825E5bd50351',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'roETH',
  },
  {
    id: 'ethereum-urlrt',
    name: 'Unified Restaked LST',
    description:
      'Introducing Unified Restaked LST (urLRT)—the future of Layered Staked Assets in EVM DeFi. urLRT embodies a revolutionary, collaborative approach, ingeniously blending yield, security, and interoperability into a seamless ecosystem. <br /><br />This cutting-edge product not only pioneers a new generation of yield-bearing assets but also serves as a robust anchor for multiple partners across intent, automation, security, and interoperability domains. Embrace the synergy with urLST, where Defi finally unifies.',
    address: '0x4f3Cc6359364004b245ad5bE36E6ad4e805dC961',
    curator: ['Quasar'],
    operator: ['Quasar'],
    points: ['mellow', 'symbiotic', 'quasar'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0x31390faA05F774aB93f86e44B633f394Be0ADb9f',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'urLRT',
  },
  {
    id: 'ethereum-steaklrt',
    name: 'Steakhouse Resteaking Vault',
    description:
      'Steakhouse Resteaking Vaults are intended to allocate to networks from a fundamental perspective, keeping in mind common sense principles and building on wstETH as the liquid asset underpinning our strategies.',
    address: '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
    curator: ['Steakhouse'],
    operator: ['Steakhouse'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0x24fee15BC11fF617c042283B58A3Bda6441Da145',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'steakLRT',
  },
  {
    id: 'ethereum-re7lrt',
    name: 'Re7 Labs LRT',
    description:
      'Re7 has been providing liquidity in DeFi since 2019 deploying capital over across yield strategies and liquid venture investing. As DeFi-native managers, we have focused on providing early liquidity to various DeFi protocols to help grow the ecosystem.<br /><br />Re7 Labs is the innovation arm of digital asset investment firm Re7 Capital, taking the practical experience of DeFi risk management to our on-chain LRT vault.',
    address: '0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a',
    curator: ['Re7 Labs'],
    operator: ['Re7 Labs'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0x70cD3464A41B6692413a1Ba563b9D53955D5DE0d',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'Re7LRT',
  },
  {
    id: 'ethereum-amphreth',
    name: 'Amphor Restaked ETH',
    description:
      'Amphor Restaked ETH [amphrETH] secures, facilitates & monitors Restaked Networks curation on Symbiotic ecosystem. <br />amprETH LRT aims at minimizing: <br />- operator authority bias<br />- unintended slashing<br />- trust/security assumptions<br />while keeping a competitive risk-adjusted yield for ETH restakers.',
    address: '0x5fD13359Ba15A84B76f7F87568309040176167cd',
    curator: ['MEVcap'],
    operator: ['MEVcap'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0xdC1741f9bD33DD791942CC9435A90B0983DE8665',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'amphrETH',
  },
  {
    id: 'ethereum-rsteth',
    name: 'Restaking Vault ETH',
    description:
      'Restaking Vault curated by P2P.org aims to maximize restaking opportunities for its users. P2P.org is a global staking & restaking leader with $8B and over 100k delegators across 50+ networks. Depositing this Vault you accept <a href="https://cdn.p2p.org/legal/restaking-vault.pdf" target="_blank">disclaimer</a>',
    address: '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
    curator: ['p2p'],
    operator: ['p2p'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0x41A1FBEa7Ace3C3a6B66a73e96E5ED07CDB2A34d',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'rstETH',
  },
  {
    id: 'ethereum-rsena',
    name: 'Ethena LRT Vault ENA',
    description:
      'Ethena Restaked ENA [rsENA] secures, facilitates and monitors restaked network curation on the Ethena ecosystem via Symbiotic. <br /> <br /> rsENA initially will be utilized to secure cross-chain messaging of USDe via LayerZero DVNs, and later multiple layers of the Ethena Chain and Dapps built on this network.',
    address: '0xc65433845ecD16688eda196497FA9130d6C47Bd8',
    curator: ['MEVcap', 'Re7 Labs', 'Nexo'],
    operator: ['MEVcap', 'Re7 Labs', 'Nexo'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    additionalTokens: [],
    lpTokenSymbol: 'rsENA',
    customApr: '—',
  },
  {
    id: 'ethereum-rsusde',
    name: 'Ethena LRT Vault sUSDe',
    description:
      'Ethena Restaked sUSDe [rsUSDe] secures, facilitates and monitors restaked network curation on the Ethena ecosystem via Symbiotic. <br /> <br /> rsUSDe initially will be utilized to secure cross-chain messaging of USDe via LayerZero DVNs, and later multiple layers of the Ethena Chain and Dapps built on this network.',
    address: '0x82f5104b23FF2FA54C2345F821dAc9369e9E0B26',
    curator: ['MEVcap', 'Re7 Labs', 'Nexo'],
    operator: ['MEVcap', 'Re7 Labs', 'Nexo'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    additionalTokens: [],
    lpTokenSymbol: 'rsUSDe',
    // See: https://ethena.fi/
    customApr: 4.3,
  },
  {
    id: 'ethereum-ifseth',
    name: 'InfStones Restaked ETH',
    description:
      'The Restaking Vault, curated by InfStones, is designed to optimize restaking opportunities for its users. <br /> <br /> InfStones is a leading Web3 infrastructure platform, managing over 20,000 nodes spanning over 80 protocols; Trusted by Binance, OKX, Lido, Puffer, Swell, StakeStone, and numerous others.',
    address: '0x49cd586dd9BA227Be9654C735A659a1dB08232a9',
    curator: ['infStones'],
    operator: ['infStones'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0x6371ca27b6c9145408909e94ff5fbb755360b914',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'ifsETH',
  },
  {
    id: 'ethereum-lugaeth',
    name: 'Luganodes Restaked ETH',
    description:
      'Luganodes Restaked ETH will help secure and facilitate networks in the Symbiotic Ecosystem. <br /> Luganodes is a leading Swiss-operated Institutional-Grade blockchain infrastructure provider, managing over $3 Billion in staked assets across 45+ networks. We run ~7%(second largest) of the Natively Restaked Ethereum on bare metal infrastructure with 99.99% uptime.',
    address: '0x82dc3260f599f4fC4307209A1122B6eAa007163b',
    curator: ['luganodes'],
    operator: ['luganodes'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0x9e03E503FD67c06d9eE5c890696bdB58C60a86cc',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'LugaETH',
  },
  {
    id: 'ethereum-coeth',
    name: 'ETH Restaking Pro',
    description:
      'Curated by Chorus One, the ETH Restaking Pro vault aims to provide users high-yield opportunities through strategic restaking. Chorus One is a research-focused institutional (re)staking leader with $3B across 50+ networks.',
    address: '0xd6E09a5e6D719d1c881579C9C8670a210437931b',
    curator: ['coETH'],
    operator: ['coETH'],
    points: ['mellow', 'symbiotic'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0x760D48996ffBCE4aB52B4c503C57d67AA63a39fE',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'coETH',
  },
  {
    id: 'ethereum-pzeth',
    name: 'Renzo Restaked LST',
    description:
      'The Restaking Vault, curated by Renzo, is designed to optimize restaking opportunities for its users.',
    address: '0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811',
    curator: ['renzo'],
    operator: ['renzo'],
    points: ['mellow', 'symbiotic', 'renzo'],
    layer: 'Symbiotic',
    network: 'ethereum',
    depositWrapper: '0x897642a9DbE1dD82AcFdB90D1f22F75B66a765bA',
    additionalTokens: [
      '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
      '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    ],
    lrtSteth: true,
    lpTokenSymbol: 'pzETH',
  },
  {
    id: 'holesky-dvsteth',
    name: 'Decentralized Validator Vault (Holesky)',
    description:
      'The Decentralized Validator Vault accepts ETH deposits to the Simple DVT Module, bridging the gap between robust institutional staking and decentralized native staking leveraging Distributed Validator Technology from Obol and SSV Network.',
    address: '0x7F31eb85aBE328EBe6DD07f9cA651a6FE623E69B',
    curator: ['Steakhouse'],
    operator: ['Steakhouse'],
    points: ['mellow', 'obol', 'ssv'],
    layer: 'Lido Simple DVT',
    network: 'holesky',
    baseToken: '0x94373a4919B3240D86eA41593D5eBa789FEF3848',
    lpTokenSymbol: 'DVstETH',
    ethDepositWithSwap: true,
  },
];

export default vaults;
