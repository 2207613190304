import { providers, VoidSigner } from 'ethers';

import { Network } from '@/shared/types';
import { sleep } from '@/shared/utils';
import { rpc, VOID_USER } from '@/core/config';

export async function iterateRpc<T>(
  network: Network,
  iterator: (rpcUrl: string) => Promise<T>,
): Promise<T> {
  const rpcs = rpc[network];
  if (rpcs.length === 0) {
    throw new Error('RPC list is empty for this network');
  }

  for (let i = 0; i < rpcs.length; i++) {
    try {
      if (rpcs[i].isAvailable) {
        // eslint-disable-next-line no-await-in-loop
        const result = await iterator(rpcs[i].url);

        return result;
      }
    } catch (error) {
      rpcs[i].isAvailable = false;
      console.error(error);
      // eslint-disable-next-line no-await-in-loop
      await sleep(200);
    }
  }
  await sleep(2000);
  rpcs.forEach(item => {
    item.isAvailable = true;
  });

  return iterateRpc(network, iterator);
}

export async function getVoidProvider(network: Network) {
  return iterateRpc(network, async url => {
    const voidProvider = new providers.JsonRpcProvider(url);

    return voidProvider;
  });
}

export async function getVoidSigner(network: Network) {
  const voidProvider = await getVoidProvider(network);
  const voidSigner = new VoidSigner(VOID_USER[network], voidProvider);

  return voidSigner;
}
