import { Defi } from './types';

const defi: Defi[] = [
  {
    id: 'pool-pendle-mlw-pzeth-26-sept',
    pool: 'Mellow (Renzo) pzETH 26 Sept 2024',
    protocol: 'Pendle',
    address: '0xd3bb297264bd6115aE163db4153038a79D78acBA',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0xd3bb297264bd6115ae163db4153038a79d78acba?chain=ethereum',
    boost: 2,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: [
      '0x9309bcD974Cd13457eab1383539b46C93e90B66A-1727308800-LP-equilibria',
      '0x9309bcD974Cd13457eab1383539b46C93e90B66A-1727308800-LP-penpie',
      '0x9309bcD974Cd13457eab1383539b46C93e90B66A-1727308800-LP-stakedao',
      '0x9309bcD974Cd13457eab1383539b46C93e90B66A-1727308800-LP-pendle',
      '0x9309bcD974Cd13457eab1383539b46C93e90B66A-1727308800-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-rsena-26-sept',
    pool: 'Mellow rsENA 26 Sept 2024',
    protocol: 'Pendle',
    address: '0xf68300929DF14d933EBa2D45917563d5ed065666',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0xf68300929df14d933eba2d45917563d5ed065666?chain=ethereum',
    boost: 2,
    vaults: ['0xc65433845ecD16688eda196497FA9130d6C47Bd8'],
    pointsAddresses: [
      '0x64046776c9C856fDc5c39c6EBa101adC8E18291f-1727308800-LP-equilibria',
      '0x64046776c9C856fDc5c39c6EBa101adC8E18291f-1727308800-LP-penpie',
      '0x64046776c9C856fDc5c39c6EBa101adC8E18291f-1727308800-LP-stakedao',
      '0x64046776c9C856fDc5c39c6EBa101adC8E18291f-1727308800-LP-pendle',
      '0x64046776c9C856fDc5c39c6EBa101adC8E18291f-1727308800-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-rsusde-26-sept',
    pool: 'Mellow rsUSDe 26 Sept 2024',
    protocol: 'Pendle',
    address: '0x4803a036c4352Bd53906B1C70040FA271D3aFE65',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x4803a036c4352bd53906b1c70040fa271d3afe65?chain=ethereum',
    boost: 2,
    vaults: ['0x82f5104b23FF2FA54C2345F821dAc9369e9E0B26'],
    pointsAddresses: [
      '0xBCD9522EEf626dD0363347BDE6cAB105c2C7797e-1727308800-LP-equilibria',
      '0xBCD9522EEf626dD0363347BDE6cAB105c2C7797e-1727308800-LP-penpie',
      '0xBCD9522EEf626dD0363347BDE6cAB105c2C7797e-1727308800-LP-stakedao',
      '0xBCD9522EEf626dD0363347BDE6cAB105c2C7797e-1727308800-LP-pendle',
      '0xBCD9522EEf626dD0363347BDE6cAB105c2C7797e-1727308800-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-amphreth-25-july',
    pool: 'Mellow (MEV Capital) amphrETH 25 July 2024',
    protocol: 'Pendle',
    address: '0x3B621df9B429Ed1AD64428Ea7d8D142374c45121',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x3b621df9b429ed1ad64428ea7d8d142374c45121?chain=ethereum',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: [
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1721865600-LP-equilibria',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1721865600-LP-penpie',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1721865600-LP-stakedao',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1721865600-LP-pendle',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1721865600-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-re7lrt-25-july',
    pool: 'Mellow (Re7Labs) Re7LRT 25 July 2024',
    protocol: 'Pendle',
    address: '0x2a41384C28B3E327bEDA4ffB3D3706CAd571d5fb',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x2a41384c28b3e327beda4ffb3d3706cad571d5fb?chain=ethereum',
    boost: 2,
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: [
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1721865600-LP-equilibria',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1721865600-LP-penpie',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1721865600-LP-stakedao',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1721865600-LP-pendle',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1721865600-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-steaklrt-25-july',
    pool: 'Mellow (Steakhouse Financial) steakLRT 25 July 2024',
    protocol: 'Pendle',
    address: '0x464F5A15Aca6Fe787Cf54fCF1E8AF6207939d297',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x464f5a15aca6fe787cf54fcf1e8af6207939d297?chain=ethereum',
    boost: 2,
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: [
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1721865600-LP-equilibria',
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1721865600-LP-penpie',
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1721865600-LP-stakedao',
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1721865600-LP-pendle',
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1721865600-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-rsteth-25-july',
    pool: 'Mellow (P2P.org) rstETH 25 July 2024',
    protocol: 'Pendle',
    address: '0xa96FeBd6c5FAF7739d3149bfB43211Ec6844a37C',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0xa96febd6c5faf7739d3149bfb43211ec6844a37c?chain=ethereum',
    boost: 2,
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: [
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1721865600-LP-equilibria',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1721865600-LP-penpie',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1721865600-LP-stakedao',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1721865600-LP-pendle',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1721865600-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-amphreth-26-sep',
    pool: 'Mellow (MEV Capital) amphrETH 26 Sep 2024',
    protocol: 'Pendle',
    address: '0x901e710C2F634131B36E3c68B072ED5DB9250f3E',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x901e710c2f634131b36e3c68b072ed5db9250f3e?chain=ethereum',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: [
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1727308800-LP-equilibria',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1727308800-LP-penpie',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1727308800-LP-stakedao',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1727308800-LP-pendle',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1727308800-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-amphor-amphrlrt-26-sep',
    pool: 'Amphor amphrLRT Pool 26 Sep 2024',
    protocol: 'Pendle',
    address: '0xb9e8bb1105382b018c6aDfd95Fd9272542Cc1776',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0xb9e8bb1105382b018c6adfd95fd9272542cc1776?chain=ethereum',
    boost: 2,
    vaults: ['0x06824C27C8a0DbDe5F72f770eC82e3c0FD4DcEc3'],
    pointsAddresses: [
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1727308800-LP-equilibria',
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1727308800-LP-penpie',
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1727308800-LP-stakedao',
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1727308800-LP-pendle',
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1727308800-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-re7lrt-26-sep',
    pool: 'Mellow (Re7 Labs) Re7LRT Pool 26 Sep 2024',
    protocol: 'Pendle',
    address: '0x020ABA13E46BaA0dDCBaB4505fABAd697e223908',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x020aba13e46baa0ddcbab4505fabad697e223908?chain=ethereum',
    boost: 2,
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: [
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1727308800-LP-equilibria',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1727308800-LP-penpie',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1727308800-LP-stakedao',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1727308800-LP-pendle',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1727308800-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-rsteth-26-sep',
    pool: 'Mellow (P2P.org) rstETH Pool 26 Sep 2024',
    protocol: 'Pendle',
    address: '0x9E27d0787B9554a1D734f60bD1365aDaF1aC298C',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x9e27d0787b9554a1d734f60bd1365adaf1ac298c?chain=ethereum',
    boost: 2,
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: [
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1727308800-LP-equilibria',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1727308800-LP-penpie',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1727308800-LP-stakedao',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1727308800-LP-pendle',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1727308800-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-steaklrt-26-sep',
    pool: 'Mellow (Steakhouse Financial) steakLRT Pool 26 Sep 2024',
    protocol: 'Pendle',
    address: '0x3Fd77572FE52b37779FE88529956bf34777CbD2b',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x3fd77572fe52b37779fe88529956bf34777cbd2b?chain=ethereum',
    boost: 2,
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: [
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1727308800-LP-equilibria',
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1727308800-LP-penpie',
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1727308800-LP-stakedao',
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1727308800-LP-pendle',
      '0x998a1d40F097Fd36bFF15cA78Fc0673Bd2a8280C-1727308800-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-amphreth-26-dec',
    pool: 'Mellow (MEV Capital) amphrETH Pool 26 Dec 2024',
    protocol: 'Pendle',
    address: '0x1C48cD1179Aa0C503A48fcD5852559942492E31b',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x1c48cd1179aa0c503a48fcd5852559942492e31b?chain=ethereum',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: [
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1735171200-LP-equilibria',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1735171200-LP-penpie',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1735171200-LP-stakedao',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1735171200-LP-pendle',
      '0x198139F26d42F2028C10427a161e2d0e8bb00346-1735171200-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-amphrlrt-26-dec',
    pool: 'amphrLRT 26 Dec 2024',
    protocol: 'Pendle',
    address: '0x7e0f3511044AFdaD9B4fd5C7Fa327cBeB90BEeBf',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x7e0f3511044afdad9b4fd5c7fa327cbeb90beebf?chain=ethereum',
    boost: 2,
    vaults: ['0x06824C27C8a0DbDe5F72f770eC82e3c0FD4DcEc3'],
    pointsAddresses: [
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1735171200-LP-equilibria',
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1735171200-LP-penpie',
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1735171200-LP-stakedao',
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1735171200-LP-pendle',
      '0x33A269066457E5E3Dc9B6dD1c7D854A7Df714B6d-1735171200-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-re7lrt-26-dec',
    pool: 'Mellow (Re7Labs) Re7LRT 26 Dec 2024',
    protocol: 'Pendle',
    address: '0x1C5B54c0BD500A7430Ed34BD2413aF17725A7840',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x1c5b54c0bd500a7430ed34bd2413af17725a7840?chain=ethereum',
    boost: 2,
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: [
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1735171200-LP-equilibria',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1735171200-LP-penpie',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1735171200-LP-stakedao',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1735171200-LP-pendle',
      '0xE833C7A12A10A44Cb8b19BdF0931053AC136F105-1735171200-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-pendle-mlw-rsteth-26-dec',
    pool: 'Mellow (P2P.org) rstETH 26 Dec 2024',
    protocol: 'Pendle',
    address: '0x409b499780Ac04dC4780F9B79fBEdE665Ac773d5',
    network: 'ethereum',
    url: 'https://app.pendle.finance/trade/points/0x409b499780ac04dc4780f9b79fbede665ac773d5?chain=ethereum',
    boost: 2,
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: [
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1735171200-LP-equilibria',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1735171200-LP-penpie',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1735171200-LP-stakedao',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1735171200-LP-pendle',
      '0x553cD5e6D2072723f5FE5457576Ed6D06b0905b2-1735171200-YT',
    ],
    showUserPoints: true,
  },
  {
    id: 'pool-curve-ezpz-eth',
    pool: 'ezpz/ETH',
    protocol: 'Curve',
    address: '0x8c65CeC3847ad99BdC02621bDBC89F2acE56934B',
    network: 'ethereum',
    url: 'https://curve.fi/#/ethereum/pools/factory-twocrypto-46',
    boost: 3,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0x8c65CeC3847ad99BdC02621bDBC89F2acE56934B'],
    showUserPoints: true,
  },
  {
    id: 'pool-curve-pzeth-wsteth',
    pool: 'pzETH/wstETH',
    protocol: 'Curve',
    address: '0xCec99Cff3632Cf1f16652cC5b1E80eB08bE25983',
    network: 'ethereum',
    url: 'https://curve.fi/#/ethereum/pools/factory-stable-ng-234',
    boost: 3,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0xCec99Cff3632Cf1f16652cC5b1E80eB08bE25983'],
    showUserPoints: true,
  },
  {
    id: 'pool-uniswap-pzeth-wsteth',
    pool: 'pzETH/wstETH',
    protocol: 'Uniswap v3',
    address: '0xfC354F5cf57125a7D85E1165F4FcDfD3006Db61A',
    network: 'ethereum',
    url: 'https://app.uniswap.org/explore/pools/ethereum/0xfc354f5cf57125a7d85e1165f4fcdfd3006db61a',
    boost: 3,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0xfC354F5cf57125a7D85E1165F4FcDfD3006Db61A'],
    showUserPoints: true,
  },
  {
    id: 'pool-uniswap-amphreth-wsteth',
    pool: 'amphrETH/wstETH',
    protocol: 'Uniswap v3',
    address: '0xf3AE577B0ce3F7CfBa28B5D07EcCEe54A959ef3C',
    network: 'ethereum',
    url: 'https://app.uniswap.org/explore/pools/ethereum/0xf3ae577b0ce3f7cfba28b5d07eccee54a959ef3c',
    boost: 3,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0xf3AE577B0ce3F7CfBa28B5D07EcCEe54A959ef3C'],
    showUserPoints: true,
  },
  {
    id: 'pool-swell-wstETH',
    pool: 'amphrETH',
    protocol: 'Swell',
    address: '0x38D43a6Cb8DA0E855A42fB6b0733A0498531d774',
    network: 'ethereum',
    url: 'https://app.swellnetwork.io/swell-l2',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0x38D43a6Cb8DA0E855A42fB6b0733A0498531d774'],
    showUserPoints: true,
  },
  {
    id: 'pool-fuel-rsteth',
    pool: 'rstETH',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
    showUserPoints: true,
  },
  {
    id: 'pool-fuel-Re7LRT',
    pool: 'Re7LRT',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
    showUserPoints: true,
  },
  {
    id: 'pool-fuel-steakLRT',
    pool: 'steakLRT',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
    showUserPoints: true,
  },
  {
    id: 'pool-fuel-amphrETH',
    pool: 'amphrETH',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
    showUserPoints: true,
  },
  {
    id: 'pool-fuel-pzETH',
    pool: 'pzETH',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
    showUserPoints: true,
  },
  {
    id: 'pool-fuel-rsUSDE',
    pool: 'rsUSDE',
    protocol: 'Fuel.network',
    address: '0x0000000000000000000000000000000000000000',
    network: 'ethereum',
    url: 'https://app.fuel.network/earn-points/deposit',
    boost: 2,
    vaults: ['0x82f5104b23FF2FA54C2345F821dAc9369e9E0B26'],
    pointsAddresses: ['0x19b5cc75846BF6286d599ec116536a333C4C2c14'],
    showUserPoints: true,
  },
  {
    id: 'pool-zircuit-rstETH',
    pool: 'rstETH',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
    showUserPoints: true,
  },
  {
    id: 'pool-zircuit-re7lrt',
    pool: 'Re7LRT',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
    showUserPoints: true,
  },
  {
    id: 'pool-zircuit-steaklrt',
    pool: 'steakLRT',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
    showUserPoints: true,
  },
  {
    id: 'pool-zircuit-amphreth',
    pool: 'amphrETH',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
    showUserPoints: true,
  },
  {
    id: 'pool-zircuit-pzeth',
    pool: 'pzETH',
    protocol: 'Zircuit',
    address: '0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6',
    network: 'ethereum',
    url: 'https://stake.zircuit.com/',
    boost: 2,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0xF047ab4c75cebf0eB9ed34Ae2c186f3611aEAfa6'],
    showUserPoints: true,
  },
  {
    id: 'pool-5pool-amphreth-rsteth-re7lrt-steaklrt-wsteth',
    pool: '5pool',
    protocol: 'Balancer',
    address: '0xBA12222222228d8Ba445958a75a0704d566BF2C8',
    network: 'ethereum',
    url: 'https://app.balancer.fi/#/ethereum/pool/0x4216d5900a6109bba48418b5e2ab6cc4e61cf4770000000000000000000006a1',
    boost: 3,
    vaults: [
      '0x5fD13359Ba15A84B76f7F87568309040176167cd',
      '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
      '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
      '0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a',
      '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
    ],
    pointsAddresses: ['0xBA12222222228d8Ba445958a75a0704d566BF2C8'],
    showUserPoints: true,
  },
  {
    id: 'pool-marginly-mlw-pt-amphreth-26-sep-2024',
    pool: 'Marginly PT Mellow amphrETH 26 Sep 2024',
    protocol: 'Marginly',
    address: '0xAc02A14F647029d2B2FF937032A2EDDfc6255A06',
    network: 'ethereum',
    url: 'https://app.marginly.com/mainnet/deposit/quote:0xAc02A14F647029d2B2FF937032A2EDDfc6255A06',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0xAc02A14F647029d2B2FF937032A2EDDfc6255A06'],
    showUserPoints: true,
  },
  {
    id: 'pool-marginly-mlw-pt-pzeth-26-sep-2024',
    pool: 'Marginly PT Mellow pzETH 26 Sep 2024',
    protocol: 'Marginly',
    address: '0x51f95AAF94834b878eec18A812D840910370887f',
    network: 'ethereum',
    url: 'https://app.marginly.com/mainnet/deposit/quote:0x51f95AAF94834b878eec18A812D840910370887f',
    boost: 2,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0x51f95AAF94834b878eec18A812D840910370887f'],
    showUserPoints: true,
  },
  {
    id: 'pool-gearbox-pzeth',
    pool: 'pzETH',
    protocol: 'Gearbox',
    address: '0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811',
    network: 'ethereum',
    url: 'https://app.gearbox.fi/restaking/open/0x8c9532a60e0e7c6bbd2b2c1303f63ace1c3e9811',
    boost: 2,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: [],
    showUserPoints: true,
  },
  {
    id: 'pool-gearbox-rstETH',
    pool: 'rstETH',
    protocol: 'Gearbox',
    address: '0x7a4EffD87C2f3C55CA251080b1343b605f327E3a',
    network: 'ethereum',
    url: 'https://app.gearbox.fi/restaking/open/0x7a4effd87c2f3c55ca251080b1343b605f327e3a',
    boost: 2,
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: [],
    showUserPoints: true,
  },
  {
    id: 'pool-gearbox-steakLRT',
    pool: 'steakLRT',
    protocol: 'Gearbox',
    address: '0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc',
    network: 'ethereum',
    url: 'https://app.gearbox.fi/restaking/open/0xbeef69ac7870777598a04b2bd4771c71212e6abc',
    boost: 2,
    vaults: ['0xBEEF69Ac7870777598A04B2bd4771c71212E6aBc'],
    pointsAddresses: [],
    showUserPoints: true,
  },
  {
    id: 'pool-gearbox-amphrETH',
    pool: 'amphrETH',
    protocol: 'Gearbox',
    address: '0x5fD13359Ba15A84B76f7F87568309040176167cd',
    network: 'ethereum',
    url: 'https://app.gearbox.fi/restaking/open/0x5fd13359ba15a84b76f7f87568309040176167cd',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: [],
    showUserPoints: true,
  },
  {
    id: 'pool-spectra-mlw-amphreth-20-sep',
    pool: 'Mellow (MEV Capital) amphrETH 20 Sep 2024',
    protocol: 'Spectra',
    address: '0x06c03069de266F58d10A0724a4f65C3D4feFfF4D',
    network: 'ethereum',
    url: 'https://app.spectra.finance/pools#mainnet/0x1c379572160a80975fa1dad8e491ff485611c8f6',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0x06c03069de266F58d10A0724a4f65C3D4feFfF4D'],
    showUserPoints: true,
  },
  {
    id: 'pool-spectra-amphrlrt-6-oct',
    pool: 'amphrLRT 6 Oct 2024',
    protocol: 'Spectra',
    address: '0xeaB93Ec2A82eB235a1f426Accd9254f2aB32A99d',
    network: 'ethereum',
    url: 'https://app.spectra.finance/pools#mainnet/0xdce8dfa05f9af2c16793f6b8e77597b7b7bf0c50',
    boost: 2,
    vaults: ['0x06824C27C8a0DbDe5F72f770eC82e3c0FD4DcEc3'],
    pointsAddresses: ['0xeaB93Ec2A82eB235a1f426Accd9254f2aB32A99d'],
    showUserPoints: true,
  },
  {
    id: 'pool-silo-amphreth',
    pool: 'amphrETH',
    protocol: 'Silo',
    address: '0x992298cb9a6aafaDBc038dC85f1a1C012F45b67d',
    network: 'ethereum',
    url: 'https://app.silo.finance/silo/0x992298cb9a6aafaDBc038dC85f1a1C012F45b67d',
    boost: 2,
    vaults: ['0x5fD13359Ba15A84B76f7F87568309040176167cd'],
    pointsAddresses: ['0x992298cb9a6aafaDBc038dC85f1a1C012F45b67d'],
    showUserPoints: true,
  },
  {
    id: 'pool-silo-re7lrt',
    pool: 'Re7LRT',
    protocol: 'Silo',
    address: '0x884725A8dd9E8591E851C88Eb665735f8D86da8C',
    network: 'ethereum',
    url: 'https://app.silo.finance/silo/0x884725A8dd9E8591E851C88Eb665735f8D86da8C',
    boost: 2,
    vaults: ['0x84631c0d0081FDe56DeB72F6DE77abBbF6A9f93a'],
    pointsAddresses: ['0x884725A8dd9E8591E851C88Eb665735f8D86da8C'],
    showUserPoints: true,
  },
  {
    id: 'pool-silo-pzeth',
    pool: 'pzETH',
    protocol: 'Silo',
    address: '0x1cdbde50F9D122CCE8fe8D08d0D482b0161fFD15',
    network: 'ethereum',
    url: 'https://app.silo.finance/silo/0x1cdbde50F9D122CCE8fe8D08d0D482b0161fFD15',
    boost: 2,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0x1cdbde50F9D122CCE8fe8D08d0D482b0161fFD15'],
    showUserPoints: true,
  },
  {
    id: 'pool-silo-rsteth',
    pool: 'rstETH',
    protocol: 'Silo',
    address: '0xb3499737F35973a73A6270ae6616eca3E2dC0f65',
    network: 'ethereum',
    url: 'https://app.silo.finance/silo/0xb3499737F35973a73A6270ae6616eca3E2dC0f65',
    boost: 2,
    vaults: ['0x7a4EffD87C2f3C55CA251080b1343b605f327E3a'],
    pointsAddresses: ['0xb3499737F35973a73A6270ae6616eca3E2dC0f65'],
    showUserPoints: true,
  },
  {
    id: 'pool-zerolend-pzeth',
    pool: 'pzETH',
    protocol: 'Zerolend',
    address: '0xd9855847FFD9Bc0c5f3efFbEf67B558dBf090a71',
    network: 'ethereum',
    url: 'https://app.zerolend.xyz/reserve-overview/?underlyingAsset=0x8c9532a60e0e7c6bbd2b2c1303f63ace1c3e9811&marketName=proto_mainnet_lrt_v3',
    boost: 2,
    vaults: ['0x8c9532a60E0E7C6BbD2B2c1303F63aCE1c3E9811'],
    pointsAddresses: ['0xd9855847FFD9Bc0c5f3efFbEf67B558dBf090a71'],
    showUserPoints: true,
  },
];

export function getPoolName(defi: Defi, points?: string) {
  return points && defi.labels ? defi.labels[points] : defi.pool;
}

export default defi;
